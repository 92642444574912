@font-face {
    font-family: 'expressa';
    src: url('../assets/fonts/expressa/expressa-webfont.woff2') format('woff2'),
    url('../assets/fonts/expressa/expressa-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'jakarta';
    src: url('../assets/fonts/plusjakarta/plus-jakarta-sans-italic-webfont.woff2') format('woff2'),
    url('../assets/fonts/plusjakarta/plus-jakarta-sans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}


@font-face {
    font-family: 'jakarta';
    src: url('../assets/fonts/plusjakarta/plus-jakarta-sans-webfont.woff2') format('woff2'),
    url('../assets/fonts/plusjakarta/plus-jakarta-sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'monument Regular';
    font-style: normal;
    font-weight: normal;
    src: local('monument Regular'), url('../assets/fonts/monument/monument-Regular.woff') format('woff');
}


@font-face {
    font-family: 'monument Book';
    font-style: normal;
    font-weight: normal;
    src: local('monument Book'), url('../assets/fonts/monument/monument-Book.woff') format('woff');
}


@font-face {
    font-family: 'monument Medium';
    font-style: normal;
    font-weight: normal;
    src: local('monument Medium'), url('../assets/fonts/monument/monument-Medium.woff') format('woff');
}
