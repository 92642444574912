:root {
    --red: #ea1a22;
    --dark: #42454f;
    --white: #ffffff;
    --orange: #eb4d2f;
}

[data-theme='dark'] {
    --color-primary: var(--orange);
    --color-secondary: var(--dark);
    --color-tertiary: var(--white);
    --color-link: var(--orange);
    --color-background: var(--dark);
}

[data-theme='light'] {
    --color-primary: var(--orange);
    --color-secondary: var(--white);
    --color-tertiary: var(--dark);
    --color-link: var(--orange);
    --color-background: var(--white);

    & .button-orange {
        color: var(--color-secondary);

        &:hover {
            color: var(--color-secondary);
        }
    }

    & .button-orange:hover > img {
        filter: brightness(0) invert(1);
    }
}

* {
    transition: all .3s ease-in-out;
}

picture {

    & img {
        width: 100%;
        height: auto;
    }
}

.flex {
    display: flex
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center
}

.flex-row {
    flex-direction: row
}

.flex-column {
    flex-direction: column
}

.flex-space-evenly {
    justify-content: space-evenly
}

.hide {
    display: none;
}

body {
    background-color: var(--color-background);
    height: clamp(100%, 100vh, 100vh);
}

main {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;
    max-height: 90%;
}

#root {
    height: max(100vh);
    justify-content: center;
    align-items: center;
}

.app {
    text-align: center;
    height: clamp(100%, 90vh, 90vh);
    background-color: var(--color-background);
    margin: 0 auto;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    > header {
        display: flex;
        padding-bottom: 3rem;
        max-width: 80%;
        align-items: center;
        flex-direction: row;
        align-content: center;
        justify-content: center;
    }


    div {
        max-height: 100%;
    }
}

.app-logo {
    max-height: 20vh;
    pointer-events: none;
}

.app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--color-tertiary);
}

.app-link, a {
    color: var(--color-link);
}

@mixin common-styles {
    color: var(--color-tertiary);
    font-weight: 500;
}

@mixin title-styles($size) {
    @include common-styles;
    font-family: jakarta, sans-serif;
    font-weight: bold;
    font-size: $size;
}

p {
    @include common-styles;
    font-size: 1rem;
    font-family: jakarta, sans-serif;
}

footer p {
    line-height: 1;
    font-family: jakarta, sans-serif;
}

h1 {
    @include title-styles(2rem);
    font-family: "monument Book", serif;
}

h2 {
    @include title-styles(1.8rem);
}

h3 {
    @include title-styles(1.4rem);
    margin-top: max(1rem);
}

h4 {
    @include title-styles(1.4rem);
    margin: max(1rem);
}

h5 {
    @include title-styles(1.2rem);
}

h6 {
    @include title-styles(1rem);
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.button-orange {
    padding: 1rem;
    color: var(--color-tertiary);
    background-color: var(--color-link);
    margin: .5rem 0;
    border-radius: 2rem;
    text-decoration: none;

    &:hover {
        color: var(--color-link);
        background-color: var(--color-tertiary);
        transition: border-radius 1s;
        border-radius: 5rem 1.5rem;

        > img {
            filter: brightness(1) invert(0);
        }
    }

    > img {
        filter: brightness(0) invert(1);
    }
}

.contact article {
    > div {
        flex-direction: column;
    }
}

footer article {
    gap: 1rem;
}

input[type='checkbox'].toggle {
    $scale: 2.5;
    $circle-diameter: calc($scale * .8rem);
    $margin-left: calc($circle-diameter * 0.12);

    $bar-width: calc($scale * 2rem);
    $bar-height: calc($scale * 1rem);

    position: absolute;
    opacity: 0;
    left: -999;
    top: -999;

    + label {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        /*toggle background*/
        &::before {
            content: " ";
            width: $bar-width;
            height: $bar-height;
            background-color: var(--color-primary);
            border-radius: calc($scale * 1rem);
        }

        /*toggle circle*/
        &::after {
            content: " ";
            position: absolute;
            left: 0;
            margin-left: $margin-left;
            width: $circle-diameter;
            height: $circle-diameter;
            background-color: var(--color-tertiary);
            border-radius: 5rem;
            transition: all .3s ease-in-out;
        }
    }

    &:checked {
        + label {
            color: var(--color-link);

            /*toggle background*/
            &::before {
                background-color: var(--color-link);
            }

            /*toggle circle*/
            &::after {
                left: calc($bar-width - $circle-diameter - ($margin-left * 2));
            }
        }
    }
}

/*
320px - 480px: Mobile devices
481px — 768px: iPads, Tablets
769px — 1024px: Small screens, laptops
1025px — 1200px: Desktops, large screens
1201px and more — Extra large screens, TV
*/

/* Media Queries */
@media (prefers-reduced-motion: no-preference) {
    .app-logo {
        animation: app-logo-spin infinite 20s linear;
    }
}

@media screen and (max-width: 480px) {
    .app {
        height: 100%;
    }

}

@media screen and (max-width: 768px) {
    #root {
        display: flex;
    }

    .app {
        height: max(90vh);

        > header {
            padding-bottom: 2rem;
        }
    }

    .contact article {
        flex-direction: column;
        align-items: stretch;

        & > div {
            display: flex;
            justify-content: center;
            gap: 1rem;
            flex-direction: row;
        }
    }

    @mixin common-styles {
        line-height: 1.4;

    }

    @mixin title-styles($size) {
        @include common-styles;
        font-size: $size;
        margin: 1rem 0;
    }

    $h1-size: 1.5rem;

    p {
        @include common-styles;
        font-size: calc($h1-size - .5rem);
        font-family: jakarta, sans-serif;

    }

    h1 {
        @include title-styles($h1-size);
    }

    h2 {
        @include title-styles(calc($h1-size - .2rem));
    }

    h3 {
        @include title-styles(calc($h1-size - .4rem));
    }

    h4 {
        @include title-styles(calc($h1-size - .4rem));
    }

    h5 {
        @include title-styles(calc($h1-size - .6rem));
    }

    h6 {
        @include title-styles(calc($h1-size - .8rem));
    }

    input[type='checkbox'].toggle {
        $scale: 1.8;
        $circle-diameter: calc($scale * .8rem);
        $margin-left: calc($circle-diameter * 0.12);

        $bar-width: calc($scale * 2rem);
        $bar-height: calc($scale * 1rem);

        + label {

            /*toggle background*/
            &::before {
                width: $bar-width;
                height: $bar-height;
                border-radius: calc($scale * 1rem);
            }

            /*toggle circle*/
            &::after {

                margin-left: $margin-left;
                width: $circle-diameter;
                height: $circle-diameter;
                border-radius: 5rem;
            }
        }

        &:checked {
            + label {
                /*toggle circle*/
                &::after {
                    left: calc($bar-width - $circle-diameter - ($margin-left * 2));
                }
            }
        }
    }

}
